import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorMessage } from '@hookform/error-message';

import CustomModal from './CustomModal';
import { LoadingLoader } from '../Loader/LopadingLoader';
import useCaptcha from '../../hooks/useCaptcha';
import { ReportReact } from '../Report/ReportTable';
import getReport from '../../lib/api/requests';

interface ReportModalProps {
  open: boolean;
  setOpen: (modalState: boolean) => void;
  setData: (data: ReportReact | null) => void;
}

type FormValues = {
  website: {
    url: string | null;
  }[];
  email: string;
  name?: string;
};

const ReportModal: React.FC<ReportModalProps> = ({ open, setOpen, setData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { recaptchaValue, recaptchaRef, handleRecaptchaChange } = useCaptcha();

  const { register, handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      website: [{ url: null }]
    },
    mode: 'onBlur'
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'website'
  });

  console.log('errors: ', formState.errors);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setLoading(true);
      setError(null);
      setData(null);
      const recaptchaValue = recaptchaRef.current?.getValue();
      const { website, email, name } = data;
      const urls: string[] = website.filter((w) => w.url !== null).map((w) => w.url) as string[];

      console.log('recaptchaValue');

      const report = await getReport({ urls, email, name, recaptchaValue: recaptchaValue as string });

      setData({
        url: report.url,
        results: report.results?.map((r) => ({
          success: r.success,
          message: r.message,
          data: r?.data,
          url: r?.url,
          issues: r?.data?.map((d) => d.nodes)?.reduce((a, b) => a + b, 0)
        }))
      });

      console.log(report);

      document.getElementById('section-result')?.scrollIntoView({ behavior: 'smooth' });

      setOpen(false);
    } catch (error: any) {
      console.error('error: ', error);
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
      reset();
    }
  };

  return (
    <>
      <CustomModal openModal={open} setOpenModal={setOpen} title={'abc'}>
        <Box position={'absolute'} top={0} right={0} pt={2}>
          <Button onClick={() => setOpen(false)} style={{ padding: '0!important' }}>
            <CloseIcon />
          </Button>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} pb={2}>
              <Typography variant={'h5'}>Get Your WCAG Report</Typography>
              <Typography variant={'body2'}>Enter your website URL and email to get your report</Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => {
                  append({ url: null });
                }}
                disabled={fields.length >= 5}
                color={'primary'}
                variant={'contained'}
              >
                <AddIcon />
              </Button>
            </Grid>
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <Grid item xs={11} key={field.id}>
                  <TextField
                    {...register(`website.${index}.url`, {
                      required: true
                      // pattern: {
                      //   value:
                      //     /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                      //   message: 'Invalid URL'
                      // }
                    })}
                    id={`website.${index}`}
                    type="text"
                    label={`${index + 1}. Website URL`}
                    variant="outlined"
                    error={!!formState.errors.website?.[index]?.url}
                    fullWidth
                    required
                  />
                  {/*<ErrorMessage errors={formState.errors} name={`website.${index}.url`} as={<Alert severity="error" />}>*/}
                  <ErrorMessage
                    errors={formState.errors}
                    name={`website.[${index}].url`}
                    as={<Typography variant={'body2'} color={'error'} />}
                  />
                </Grid>
                <Grid item xs={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <IconButton onClick={() => remove(index)} disabled={fields.length === 1} color={'primary'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <TextField
                {...register('email', { required: false })}
                id="email"
                type="email"
                name="email"
                label="E-mail"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('name')}
                id="name"
                type="text"
                name="name"
                label="Your name"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} mt={1}>
              <Typography variant={'body2'}>
                By clicking "GET YOUR REPORT NOW", you agree to our Terms of Services and Privacy Policy. By providing
                your email and name, you will receive a detailed WCAG report for your website. Your personal data will
                be stored for a maximum of 6 months. You can withdraw your consent at any time by contacting us at{' '}
                <a href={'mailto:support@mnb.solutions'} className={'email-link'}>
                  support@mnb.solutions
                </a>
                .
              </Typography>
            </Grid>

            <Grid item xs={12} mt={2}>
              <ReCAPTCHA
                ref={recaptchaRef}
                onChange={handleRecaptchaChange}
                sitekey="6LdnujQqAAAAAJI-RcjmXiPKqfLuvwnIsny1vJbN"
              />
            </Grid>

            <Grid item xs={12} mt={0} justifyContent={'center'} display={'flex'}>
              <Button
                variant={'contained'}
                color={'primary'}
                type={'submit'}
                disabled={formState.isValid && !recaptchaValue}
              >
                Get Your Report Now
              </Button>
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </form>
      </CustomModal>
      {loading && <LoadingLoader />}
    </>
  );
};

export default ReportModal;
