import { Box, useMediaQuery } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import ReactModal, { Styles } from 'react-modal';

ReactModal.setAppElement(document.getElementById('root') as HTMLElement);

interface IModalProps {
  title: string;
  openModal: boolean;
  setOpenModal: (modalState: boolean) => void;
  children: ReactNode;
  width?: number;
  height?: string;
  maxHeight?: string;
}

const Modal: React.FC<IModalProps> = ({ title, openModal, setOpenModal, children, width, height, maxHeight }) => {
  const matchesXs = useMediaQuery('(max-width:901px)');
  const getWidth = () => {
    if (width) {
      if (matchesXs) {
        const widthDelta = 100 - width;

        return `${width + widthDelta * 0.5}%`;
      }
      return `${width}%`;
    }
    if (matchesXs) {
      return '80%';
    }
    return '60%';
  };

  const getMaxHeight = () => {
    if (height) {
      return `${height}vh`;
    }
    if (matchesXs) {
      return '100vh';
    }
    return '100vh';
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [openModal]);

  const customStyles = {
    overlay: {
      zIndex: 10
    },
    content: {
      width: getWidth(),
      // height: height && height?.length >= 1 ? height : '100%',
      maxHeight: maxHeight || getMaxHeight(),
      backgroundColor: '#0C0A33',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // overflow: 'scroll',
      overflowY: 'auto',
      fontSize: '22px !important',
      minWidth: '150px',
      boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.25)'
    }
  } as Styles;

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <ReactModal isOpen={openModal} onRequestClose={closeModal} style={customStyles} contentLabel={title}>
      <Box id="modalRoot" py={2}>
        {children}
      </Box>
    </ReactModal>
  );
};

export default Modal;
