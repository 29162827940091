import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import logo from '../../lib/img/logo.png';
import Browser from '../Browser/Browser';
import LazyShow from '../LazyShow/LazyShow';

const Navbar = () => {
  return (
    <nav className="main-bg" role="navigation">
      <Box pt={1} />
      <img src={logo} alt={'MNB Solutions logo'} width={'10%'} height={'100%'} />
    </nav>
  );
};

const Footer = () => {
  return (
    <footer className={'footer-bg'} role="contentinfo">
      <Box pt={10} />
      <Grid container justifyContent={'center'} justifyItems={'center'}>
        <LazyShow>
          <Grid item xs={12}>
            <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'}>
              Do you have any question?
            </Typography>
          </Grid>
          <Grid item xs={12} pt={2} display={'flex'} justifyContent={'center'}>
            <Typography variant={'h4'} component={'span'} fontWeight={'bold'}>
              <a href={'mailto:welcome@mnb.solutions'} className={'email-link'}>
                welcome@mnb.solutions
              </a>
            </Typography>
          </Grid>
          <Box py={10} />
          <Grid item xs={12} pt={2}>
            <Typography variant={'body1'} textAlign={'center'}>
              © {new Date().getFullYear()} MNB solutions /{' '}
              <a href={'https://www.linkedin.com/company/mnb-solutions'} className={'link'}>
                LinkedIn
              </a>
              {' / '}
              <a href={'https://www.instagram.com/mnbsolutions/'} className={'link'}>
                Instagram
              </a>
              {' / '}
              <a href={'https://www.facebook.com/MNBSolutions/'} className={'link'}>
                Facebook
              </a>
            </Typography>
          </Grid>
        </LazyShow>
      </Grid>
    </footer>
  );
};

const App: React.FC = () => {
  return (
    <div>
      {/*<Navbar />*/}
      <Browser />
      <Footer />
    </div>
  );
};

export default App;
