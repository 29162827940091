import { useEffect } from 'react';
import { motion, useAnimate } from 'framer-motion';

export const LoadingLoader = () => {
  const [scope, animate] = useAnimate();
  // const LOADING_TEXT = 'LOADING PAGE & WCAG REPORT.';
  const LOADING_TEXT = 'CREATING YOUR WCAG REPORT.';
  const characters = LOADING_TEXT.split('');
  const radius = 100;
  const fontSize = '20px';
  const letterSpacing = 13.5;

  useEffect(() => {
    const animateLoader = async () => {
      const letterAnimation: { [key: string]: any } = [];
      characters.forEach((_, i) => {
        letterAnimation.push([`.letter-${i}`, { opacity: 0 }, { duration: 0.3, at: i === 0 ? '+0.2' : '-0.28' }]);
      });
      characters.forEach((_, i) => {
        letterAnimation.push([`.letter-${i}`, { opacity: 1 }, { duration: 0.3, at: i === 0 ? '+0.2' : '-0.28' }]);
      });
      animate(letterAnimation, {
        ease: 'linear',
        repeat: Infinity
      });
      animate(scope.current, { rotate: 360 }, { duration: 4, ease: 'linear', repeat: Infinity });
    };
    animateLoader();
  }, [animate, characters, scope]);

  return (
    <div className={'overlay'}>
      <div className={'container'}>
        <motion.div ref={scope} className="circle" style={{ width: radius * 2 }}>
          <p aria-label={LOADING_TEXT} />
          <p aria-hidden="true" className="text">
            {characters.map((ch, i) => (
              <motion.span
                key={i}
                className={`letter letter-${i}`}
                style={{
                  transformOrigin: `0 ${radius}px`,
                  transform: `rotate(${i * letterSpacing}deg)`,
                  fontSize
                }}
              >
                {ch}
              </motion.span>
            ))}
          </p>
        </motion.div>
      </div>
    </div>
  );
};

// export const LoadingLoader = () => {
//   useEffect(() => {
//     document.body.style.overflow = 'hidden';
//     return () => {
//       document.body.style.overflow = 'unset';
//     };
//   }, []);
//
//   return (
//     <div className={'overlay'}>
//       <div className={'container'}>
//         <Circles
//           height="80"
//           width="80"
//           color="#ffffff"
//           ariaLabel="circles-loading"
//           wrapperStyle={{}}
//           wrapperClass=""
//           visible={true}
//         />
//       </div>
//     </div>
//   );
// };
