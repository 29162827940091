import React, { useCallback } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';

import ReportModal from '../../components/CustomModal/ReportModal';
import LazyShow from '../../components/LazyShow/LazyShow';
import ReportTable, { ReportReact } from '../../components/Report/ReportTable';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#16143B'
  },
  '&:last-child td, &:last-child th': { border: 0 }
}));

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState<ReportReact | null>(null);

  const handleSetReport = useCallback((data: ReportReact | null) => {
    setReport(data);
  }, []);

  return (
    <main role="main">
      <section id={'section-1'} className={'main-bg'}>
        <Box py={7} />
        <div className={'card-body'}>
          <div className={'card-layout'}>
            <div className={'card-content'}>
              <div className={'card'}>
                <LazyShow>
                  <Typography variant={'h1'} fontWeight={'500'} pb={2}>
                    Get Your WCAG Audit Report
                  </Typography>
                  <Typography variant={'h2'}>Powered by AccessiBridge</Typography>
                  <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                    Ensure your website's digital accessibility with AccessiBridge, a powerful tool that scans websites
                    for compliance with WCAG, EAA, and BFSG standards.{' '}
                  </Typography>
                  <Box pt={5}>
                    <Button variant={'contained'} color={'primary'} onClick={() => setOpen(true)}>
                      Get Free Audit Report
                    </Button>
                  </Box>
                </LazyShow>
              </div>
              <div className={'card'}>
                {/*<img src={mainImage} alt={'logo'} width={'100%'} className={'card-image'} />*/}
                <div className={'card-image'} />
              </div>
            </div>
          </div>
        </div>
        <Box py={7} />
      </section>
      <section id={'section-result'} className={'main-bg'}>
        {report && (
          <>
            <Box py={7} />
            <Paper
              sx={(theme) => ({
                p: 5,
                margin: 'auto',
                maxWidth: 1250,
                // flexGrow: 1,
                backgroundColor: 'transparent',
                ...theme.applyStyles('dark', {
                  backgroundColor: '#1A2027'
                })
              })}
              elevation={0}
            >
              <Grid container justifyContent={'space-between'} columnGap={1}>
                <Grid item xs={12}>
                  <Typography variant={'h2'} fontWeight={'normal'} pb={2}>
                    AccessiBridge Report Overview
                  </Typography>
                </Grid>

                {report.results.map((result, index) => (
                  <ReportTable key={index} report={result} fullReportUrl={report.url} />
                ))}
              </Grid>
            </Paper>
            <Box py={7} />
          </>
        )}
      </section>
      <section id={'section-2'} style={{ position: 'relative' }}>
        <div className={'image-bg-1'} />
        <Box py={7} />
        <Paper
          sx={(theme) => ({
            p: 5,
            margin: 'auto',
            maxWidth: 920,
            // flexGrow: 1,
            backgroundColor: 'transparent',
            ...theme.applyStyles('dark', {
              backgroundColor: '#1A2027'
            })
          })}
          elevation={0}
        >
          <Grid container justifyContent={'space-between'} columnGap={1}>
            <Grid item xs={12}>
              <LazyShow>
                <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                  About AccessiBridge and Digital Accessibility
                </Typography>
              </LazyShow>
            </Grid>

            <Grid item xs={12} sm={5.9} className={'card-bg'} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  Understanding WCAG
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  WCAG (Web Content Accessibility Guidelines) are a set of recommendations for making web content more
                  accessible to a wider range of people with disabilities. These guidelines are perceivable, operable,
                  understandable, and robust for all users, regardless of their abilities or the devices they use.
                </Typography>
                <List
                  sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item'
                    }
                  }}
                  role={'list'}
                >
                  <ListItem>
                    Perceivable: Information and user interface components must be presented in a way that users can
                    perceive
                  </ListItem>
                  <ListItem>Operable: The interface and navigation must be operable by all users.</ListItem>
                  <ListItem>
                    Understandable: The information and operation of the user interface must be understandable.
                  </ListItem>
                  <ListItem>
                    Robust: Content must be robust enough that it can be interpreted reliably by a wide variety of user
                    agents.
                  </ListItem>
                </List>
              </LazyShow>
            </Grid>
            <Grid item xs={12} sm={5.9} className={'card-bg'} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  Importance of Digital Accessibility
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  Digital accessibility is crucial for several reasons:
                </Typography>
                <List
                  sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item'
                    }
                  }}
                >
                  <ListItem>
                    Inclusion: It ensures that people with disabilities can access and use digital content and services
                  </ListItem>
                  <ListItem>
                    Legal Compliance: Many countries have laws requiring digital accessibility, including the European
                    Accessibility Act.
                  </ListItem>
                  <ListItem>
                    Improved User Experience: Accessible websites often provide a better experience for all users, not
                    just those with disabilities
                  </ListItem>
                  <ListItem>
                    Wider Reach: Accessible websites can reach a broader audience, including the growing elderly
                    population.
                  </ListItem>
                </List>
              </LazyShow>
            </Grid>
          </Grid>
        </Paper>
        <Box py={7} />
      </section>
      <section id={'section-3'} className={'main-bg'}>
        {/*<div className={'image-bg-2'} />*/}
        <Box py={7} />
        <Paper
          sx={(theme) => ({
            p: 5,
            margin: 'auto',
            maxWidth: 920,
            // flexGrow: 1,
            backgroundColor: 'transparent',
            ...theme.applyStyles('dark', {
              backgroundColor: '#1A2027'
            })
          })}
          elevation={0}
        >
          <Grid container justifyContent={'space-between'} columnGap={1}>
            <Grid item xs={12}>
              <LazyShow>
                <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                  The European Accessibility Act
                </Typography>
              </LazyShow>
            </Grid>

            <Grid item xs={12} py={10}>
              <div className="timeline-container">
                <div className="timeline">
                  <LazyShow>
                    <div className="marker" style={{ left: '25%' }}>
                      <div className="label">1</div>
                    </div>
                  </LazyShow>
                  <LazyShow>
                    <div className="marker" style={{ left: '75%' }}>
                      <div className="label">2</div>
                    </div>
                  </LazyShow>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={5.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  European Accessibility Act Countdown
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  The European Accessibility Act was passed in 2019, and most states are currently in the process of
                  implementing it. The act will come into effect on 28 June 2020. This act aims to improve the
                  accessibility of digital products and services by removing barriers created by divergent rules in
                  Member States.
                </Typography>
              </LazyShow>
            </Grid>
            <Grid item xs={12} sm={5.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  305 days and 15 hours until EAA takes effect
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  This countdown highlights the urgency of ensuring your website's compliance with the EAA. It's crucial
                  to act now to avoid potential legal issues and ensure that your website is accessible to all users.
                </Typography>
              </LazyShow>
            </Grid>
          </Grid>
        </Paper>
        <Box py={7} />
      </section>
      <section id={'section-4'} style={{ position: 'relative' }}>
        <div className={'image-bg-2'} />
        <Box py={7} />
        <Paper
          sx={(theme) => ({
            p: 5,
            margin: 'auto',
            maxWidth: 920,
            // flexGrow: 1,
            backgroundColor: 'transparent',
            ...theme.applyStyles('dark', {
              backgroundColor: '#1A2027'
            })
          })}
          elevation={0}
        >
          <Grid container justifyContent={'space-between'} columnGap={1}>
            <Grid item xs={12}>
              <LazyShow>
                <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                  Our 3-Step Audit Process
                </Typography>
              </LazyShow>
            </Grid>

            <Grid item xs={12} py={2}>
              <LazyShow>
                <div className="chevron-container">
                  <div className="chevron">
                    <span>1</span>
                  </div>
                  <div className="chevron">
                    <span>2</span>
                  </div>
                  <div className="chevron">
                    <span>3</span>
                  </div>
                </div>
              </LazyShow>
            </Grid>
            <Grid item xs={12} sm={3.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  AccessiBridge Insight Report
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  Our initial scan covers all sites under one domain, producing a high-level, easily understood report
                  showing all digital accessibility issues. This report helps stakeholders understand the scope of the
                  problem. For example, it might highlight that "5 pictures on the home page do not meet the necessary
                  contrast criteria."
                </Typography>
              </LazyShow>
            </Grid>
            <Grid item xs={12} sm={3.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  AccessiBridge Developer's Roadmap
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  These detailed reports build on the initial scan and provide information about specific issues
                  identified. They help developers pinpoint and address issues efficiently. For instance, it might
                  specify which pictures do not meet the necessary contrast criteria, enabling developers to fix them
                  quickly.
                </Typography>
              </LazyShow>
            </Grid>
            <Grid item xs={12} sm={3.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  AccessiBridge Expert Audit
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  This final step involves a thorough review by our accessibility experts. They ensure that all issues
                  are addressed and that your website meets the highest standards of accessibility. This audit provides
                  a comprehensive assessment of your website's compliance with WCAG, EAA, and BFSG standards.
                </Typography>
              </LazyShow>
            </Grid>
          </Grid>
        </Paper>
        <Box py={7} />
      </section>
      {/*<section id={'section-5'} style={{ position: 'relative' }}>*/}
      {/*  <div className={'image-bg-3'} />*/}
      {/*  <Box py={7} />*/}
      {/*  <Paper*/}
      {/*    sx={(theme) => ({*/}
      {/*      p: 5,*/}
      {/*      margin: 'auto',*/}
      {/*      maxWidth: 920,*/}
      {/*      // flexGrow: 1,*/}
      {/*      backgroundColor: 'transparent',*/}
      {/*      ...theme.applyStyles('dark', {*/}
      {/*        backgroundColor: '#1A2027'*/}
      {/*      })*/}
      {/*    })}*/}
      {/*    elevation={0}*/}
      {/*  >*/}
      {/*    <Grid container justifyContent={'space-between'} columnGap={1}>*/}
      {/*      <Grid item xs={12}>*/}
      {/*        <LazyShow>*/}
      {/*          <Typography variant={'h2'} fontWeight={'normal'} pb={2}>*/}
      {/*            Ensure Your Website's Digital Accessibility*/}
      {/*          </Typography>*/}
      {/*        </LazyShow>*/}
      {/*      </Grid>*/}

      {/*      <Grid item xs={12} py={2}>*/}
      {/*        <LazyShow>*/}
      {/*          <TableContainer>*/}
      {/*            <Table*/}
      {/*              sx={{ minWidth: 650, border: '0.0625em solid rgba(255, 255, 255, 0.24)' }}*/}
      {/*              aria-label="simple table"*/}
      {/*            >*/}
      {/*              <TableHead>*/}
      {/*                <TableRow>*/}
      {/*                  <TableCell>Severity</TableCell>*/}
      {/*                  <TableCell>Issue Type</TableCell>*/}
      {/*                  <TableCell>Count</TableCell>*/}
      {/*                </TableRow>*/}
      {/*              </TableHead>*/}
      {/*              <TableBody>*/}
      {/*                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>*/}
      {/*                  <TableCell>Critical</TableCell>*/}
      {/*                  <TableCell>Missing Alternative Text</TableCell>*/}
      {/*                  <TableCell>15</TableCell>*/}
      {/*                </TableRow>*/}
      {/*                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>*/}
      {/*                  <TableCell>Major</TableCell>*/}
      {/*                  <TableCell>Color Contrast</TableCell>*/}
      {/*                  <TableCell>1</TableCell>*/}
      {/*                </TableRow>*/}
      {/*                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>*/}
      {/*                  <TableCell>Moderate</TableCell>*/}
      {/*                  <TableCell>Keyboard Navigation</TableCell>*/}
      {/*                  <TableCell>3</TableCell>*/}
      {/*                </TableRow>*/}
      {/*              </TableBody>*/}
      {/*            </Table>*/}
      {/*          </TableContainer>*/}
      {/*        </LazyShow>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12} py={2}>*/}
      {/*        <LazyShow>*/}
      {/*          <Typography variant={'body1'} fontWeight={'normal'}>*/}
      {/*            While automated tests can detect about 60% of all issues, our experts address the remaining 40%. This*/}
      {/*            includes nuanced evaluations such as ensuring picture alternatives are accurate compared to the actual*/}
      {/*            image content.*/}
      {/*          </Typography>*/}
      {/*        </LazyShow>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12} py={2}>*/}
      {/*        <LazyShow>*/}
      {/*          <Button variant={'contained'} color={'primary'}>*/}
      {/*            Download Full Sample Report*/}
      {/*          </Button>*/}
      {/*        </LazyShow>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </Paper>*/}
      {/*  <Box py={7} />*/}
      {/*</section>*/}
      <section id={'section-6'} className={'main-bg'}>
        <div className={'image-bg-2'} />
        <Box py={7} />
        <Paper
          sx={(theme) => ({
            p: 5,
            margin: 'auto',
            maxWidth: 920,
            // flexGrow: 1,
            backgroundColor: 'transparent',
            ...theme.applyStyles('dark', {
              backgroundColor: '#1A2027'
            })
          })}
          elevation={0}
        >
          <Grid container justifyContent={'space-between'} columnGap={1}>
            <Grid item xs={12}>
              <LazyShow>
                <Typography variant={'h3'} fontWeight={'normal'} pb={2}>
                  3-Step Plan To Achieve WCAG Compliance
                </Typography>
              </LazyShow>
            </Grid>

            <Grid item xs={12} sm={3.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  Compliance Assessment
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  We start with AccessiBridge Audits to assess your current compliance status and identify areas that
                  need improvement.
                </Typography>
              </LazyShow>
            </Grid>
            <Grid item xs={12} sm={3.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  Achieve Compliance
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  This crucial step involves developing and implementing solutions to address the identified
                  accessibility issues. We help with implementation, providing support and guidance throughout the
                  process, ensuring that issues are resolved effectively.
                </Typography>
              </LazyShow>
            </Grid>
            <Grid item xs={12} sm={3.9} p={2}>
              <LazyShow>
                <Typography variant={'h4'} fontWeight={'normal'} className={'card-title'}>
                  Remain Compliant
                </Typography>
                <Typography variant={'body1'} fontWeight={'normal'} pt={2}>
                  To maintain ongoing compliance, we deploy the AccessiBridge Continuity solution. This ensures your
                  digital assets stay accessible and compliant with the latest standards.
                </Typography>
              </LazyShow>
            </Grid>
          </Grid>
        </Paper>
        <Box py={7} />
      </section>
      <section id={'section-7'} className={'main-bg-light'}>
        <div className={'image-bg-2'} />
        <Box py={7} />
        <Paper
          sx={(theme) => ({
            p: 5,
            margin: 'auto',
            maxWidth: 920,
            // flexGrow: 1,
            backgroundColor: '#0C0A33',
            ...theme.applyStyles('dark', {
              backgroundColor: '#0C0A33'
            })
          })}
          elevation={0}
        >
          <Grid container justifyContent={'space-between'} columnGap={1}>
            <Grid item xs={12}>
              <LazyShow>
                <Typography variant={'h2'} fontWeight={'normal'} pb={2}>
                  AccessiBridge Solutions
                </Typography>
              </LazyShow>
            </Grid>

            <Grid item xs={12} py={2}>
              <LazyShow>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650, border: '0.0625em solid rgba(255, 255, 255, 0.24)' }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: '#16143B'
                        }}
                      >
                        <TableCell>Feature</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>AccessiBridge Basic</TableCell>
                        <TableCell>AccessiBridge</TableCell>
                        <TableCell>AccessiBridge Pro</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <TableCell>AccessiBridge Insight Report</TableCell>
                        <TableCell>
                          Automated report that categorizes deviations according to their criticality and detects up to
                          50% of deviations.
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell>AccessiBridge Developer's Roadmap</TableCell>
                        <TableCell>
                          Detailed technical report with precise instructions for addressing accessibility issues in the
                          code.
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell>AccessiBridge Expert Audit</TableCell>
                        <TableCell>
                          Detailed automated and expert-led report that detects up to 100% of deviations. Followed by
                          the implementation of measures to ensure compliance.
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell>Report Customization</TableCell>
                        <TableCell>Optional customization of the report to specific customer needs.</TableCell>
                        <TableCell>On demand</TableCell>
                        <TableCell>On demand</TableCell>
                        <TableCell>On demand</TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell>Continuous Site Monitoring</TableCell>
                        <TableCell>Regular evaluations to detect new websites and changes to existing pages</TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell>Automated Functional Tests</TableCell>
                        <TableCell>
                          Automated functional tests for detecting functional problems, such as defective navigation and
                          links.
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell>User-Friendly Dashboard</TableCell>
                        <TableCell>Interactive custom dashboard with all audit reports, with regular updates</TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                        <TableCell>
                          <DoneIcon />
                        </TableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </LazyShow>
            </Grid>
          </Grid>
        </Paper>
        <Box py={7} />
      </section>
      <section id={'section-8'} className={'main-bg'}>
        <div className={'image-bg-2'} />
        <Box py={7} />
        <Paper
          sx={(theme) => ({
            p: 5,
            margin: 'auto',
            maxWidth: 920,
            // flexGrow: 1,
            backgroundColor: 'transparent',
            ...theme.applyStyles('dark', {
              backgroundColor: '#1A2027'
            })
          })}
          elevation={0}
        >
          <Grid container justifyContent={'space-between'} columnGap={1}>
            <LazyShow>
              <Grid item xs={12}>
                <Typography variant={'h2'} fontWeight={'normal'} pb={5}>
                  I Need This - Get In Touch
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    window.location.href = 'mailto:support@mnb.solutions?subject=WCAG Get In Touch';
                  }}
                >
                  {/*<a*/}
                  {/*  href={'mailto:support@mnb.solutions?subject=WCAG Get In Touch'}*/}
                  {/*  style={{ color: 'white', textDecoration: 'none' }}*/}
                  {/*>*/}
                  Get Personalized Offer
                  {/*</a>*/}
                </Button>
              </Grid>
            </LazyShow>
          </Grid>
        </Paper>
        <Box py={7} />
      </section>
      <ReportModal open={open} setOpen={setOpen} setData={handleSetReport} />
    </main>
  );
};

export default Home;
