import React, { useEffect, useState } from 'react';

interface IUseOnScreenProps {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  rootMargin?: string;
}

const useOnScreen = ({ ref, rootMargin = '-200px' }: IUseOnScreenProps) => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin: rootMargin
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
  }, [ref, rootMargin]);

  return isIntersecting;
};

export default useOnScreen;
