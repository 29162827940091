import { useState, useRef, useCallback, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const useCaptcha = () => {
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleRecaptchaChange = useCallback((value: string | null) => {
    setRecaptchaValue(value || null);
  }, []);

  useEffect(() => {
    const refreshCaptcha = () => {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setRecaptchaValue(null);
      }
    };

    let interval: NodeJS.Timeout;

    if (recaptchaValue) {
      interval = setInterval(refreshCaptcha, 2.5 * 60 * 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [recaptchaValue]);

  return { recaptchaValue, setRecaptchaValue, recaptchaRef, handleRecaptchaChange };
};

export default useCaptcha;
